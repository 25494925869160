<template>
    <div :style='{"font-size":"7pt", "line-height": "normal"}'>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Prevención de caidas
        </div>
        <div>
            {{datos?datos.caidas.split(',').join(', '):''}}
        </div>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Prevención de úlceras por presión
        </div>
        <div>
            {{datos?datos.upp.split(',').join(', '):''}}
        </div>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Cuidado de heridas
        </div>
        <div>
            {{datos?datos.heridas.split(',').join(', '):''}}
        </div>

        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Accesos vasculares
        </div>

        {{datos?datos.accesosVasc.split(',').join(', '):''}}

        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Fototerapia
        </div>
        {{datos?datos.fototerapia:''}}

    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>